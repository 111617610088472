import { AuthGuardService } from './../../services/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrincipalLayoutComponent } from '../../layout/principal-layout/principal-layout.component';
import { NoticiasComponent } from './noticias.component';

const routes: Routes = [
  {
    path: 'noticias',
    component: PrincipalLayoutComponent,
    // canActivate: [AuthGuardService],
    children: [
      { path: '', component: NoticiasComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoticiasRoutingModule { }

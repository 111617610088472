import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Paginator } from 'src/app/classes/paginator';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
import { NoticiasRoutingModule } from './noticias-routing.module';
import { NoticiasComponent, PreviewDialog } from './noticias.component';




@NgModule({
  declarations: [NoticiasComponent, PreviewDialog],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: Paginator
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptorService,
    //   multi: true
    // }
  ],
  imports: [
    CommonModule,
    NoticiasRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatDividerModule,
    MatCardModule,
    MatChipsModule,
    MatPaginatorModule,
    HttpClientModule,
    // MatSortModule,
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule
  ],
  entryComponents: [NoticiasComponent, PreviewDialog]
})
export class NoticiasModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { PrincipalLayoutComponent } from './principal-layout/principal-layout.component';


@NgModule({
  declarations: [LoginLayoutComponent, PrincipalLayoutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    FlexLayoutModule,
    BrowserAnimationsModule
  ],
  exports: [LoginLayoutComponent, PrincipalLayoutComponent],
})
export class LayoutModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwsService {
  constructor(private http: HttpClient) {}

  deleteFile(filename: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        fileName: filename
      }
    };

    return this.http.delete(`${environment.apiURL}/delete-file`, options);
  }

}

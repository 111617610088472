// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://obs04mumqk.execute-api.us-east-1.amazonaws.com/v1/s3',
  //s3URL: 'https://s3.amazonaws.com/upax-conekta-dev/imagenes-noticias/',
  firebase: {
    apiKey: "AIzaSyCc4nP3OkBC2kOC-JgjBDyqdCU3_HN-WpI",
    authDomain: "bienestar-dev.firebaseapp.com",
    databaseURL: "https://bienestar-dev.firebaseio.com",
    projectId: "bienestar-dev",
    storageBucket: "bienestar-dev.appspot.com",
    messagingSenderId: "613089296997",
    appId: "1:613089296997:web:fe8b35b8aaa7c55eee3c8c",
    measurementId: "G-RJT9TKN5BN"
  },

  cognitoSettings: {
    UserPoolId: 'us-east-1_zQzcrrN1i',
    ClientId: '2mh014ne7dsgau7nptbea08fdi'
  },


  awsConfig: {
    accessKeyId: 'AKIAS4A5364DYGRHASDI',
    secretAccessKey: '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',
    region: 'us-east-1'
  },
  bucket: 'dev.survey.files'

  //
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

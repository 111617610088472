import { Categoria } from '../interfaces/categoria';
import { Descripcion } from '../interfaces/descripcion';
import { Keyword } from '../interfaces/keyword';
import { Noticia } from '../interfaces/noticia';
import { Titulo } from '../interfaces/titulo';
import { Compania } from './../interfaces/compania';

export class NoticiaImpl implements Noticia {
  id?: string;
  title: Titulo;
  partialDescription: Descripcion;
  companys: string[];
  principalImage: string;
  secondaryImage: string;
  category: Categoria;
  body: string;
  keywords: Keyword[];
  creationDate: Date;
  displayDate: Date;
  hideDate: Date;
  companysDetail?: Compania[];
  priority?: number;
}

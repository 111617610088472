import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MAT_DIALOG_DATA
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { AwsService } from "src/app/services/aws.service";
import { Noticia } from "../../interfaces/noticia";
import { FirestoreService } from "../../services/firestore.service";
import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';
import { Key } from 'protractor';

@Component({
  selector: "app-noticias",
  templateUrl: "./noticias.component.html",
  styleUrls: ["./noticias.component.scss"]
})
export class NoticiasComponent implements OnInit {
  news: Noticia[];
  showMenu = false;
  searchedAll = false;
  previewObject: any = {};
  displayedColumns: string[] = [
    "title",
    "category",
    "company",
    "partialDescription",
    'showDate',
    'hideDate',
    "accion"
  ];

  dataSource: MatTableDataSource<Noticia>;
  s3: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("confirmDelete") private confirmDelete: SwalComponent;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private firestoreService: FirestoreService,
    private awsService: AwsService,
    private route: ActivatedRoute
  ) { 
    AWS.config.update(environment.awsConfig);
    this.s3 = new AWS.S3();
  }

  ngOnInit() {
    this.spinner.show();
    if (this.route.snapshot.queryParamMap.get("embd") === "1") {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }
    this.loadDataTable();
  }

  loadDataTable() {
    this.firestoreService.getGreaterNews().subscribe(data => {
      this.news = data.map(e => {
        const obj = {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Noticia;
        return obj;
      });

      this.news.sort(this.comprareByCreationDate);

      this.dataSource = new MatTableDataSource(this.news);

      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data)
          .reduce(accumulator, "")
          .toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };

      this.dataSource.sortingDataAccessor = (
        data: any,
        sortHeaderId: string
      ): string | number => {
        let value = null;
        if (sortHeaderId.includes(".")) {
          const ids = sortHeaderId.split(".");
          value = data;
          ids.forEach(function (x) {
            value = value ? value[x] : null;
          });
        } else {
          value = data[sortHeaderId];
        }
        return value;
      };

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.searchedAll = false;
      this.spinner.hide();
    });
  }

  loadAllTable(filterValue: string) {
    this.firestoreService.getNews().subscribe(data => {
      this.news = data.map(e => {
        const obj = {
          id: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Noticia;
        return obj;
      });

      this.news.sort(this.comprareByCreationDate);

      this.dataSource = new MatTableDataSource(this.news);

      this.dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data)
          .reduce(accumulator, "")
          .toLowerCase();
        // Transform the filter by converting it to lowercase and removing whitespace.
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };

      this.dataSource.sortingDataAccessor = (
        data: any,
        sortHeaderId: string
      ): string | number => {
        let value = null;
        if (sortHeaderId.includes(".")) {
          const ids = sortHeaderId.split(".");
          value = data;
          ids.forEach(function (x) {
            value = value ? value[x] : null;
          });
        } else {
          value = data[sortHeaderId];
        }
        return value;
      };

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      this.searchedAll = true;
      this.spinner.hide();
    });
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === "object") {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  preview(element) {
    const dialogRef = this.dialog.open(PreviewDialog, {
      width: "500px",
      data: { values: element },
      autoFocus: false
    });
    dialogRef.afterOpened().subscribe(() => { });

    dialogRef.afterClosed().subscribe(result => { });
  }

  // Aplica el filtro
  applyFilter(filterValue: string) {
    if (filterValue && this.searchedAll) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    } else if (filterValue) {
      this.spinner.show();
      this.loadAllTable(filterValue);
    } else {
      this.spinner.show();
      this.loadDataTable();
    }
  }

  // Abre la sección de nueva noticia
  openNew() {
    const embeded = "/noticias-alta" + (!this.showMenu ? "?embd=1" : "");
    this.router.navigateByUrl(embeded);
  }

  editNews(idNews) {
    const embeded =
      "/noticias-alta/" + idNews + (!this.showMenu ? "?embd=1" : "");
    this.router.navigateByUrl(embeded);
  }

  delete(element) {
    this.spinner.show();
    const principalImageName = element.principalImage.substr(
      element.principalImage.lastIndexOf("/") + 1,
      element.principalImage.length
    );
    const secondaryImageName = element.secondaryImage.substr(
      element.secondaryImage.lastIndexOf("/") + 1,
      element.secondaryImage.length
    );




    const principal = {
      Bucket: environment.bucket,
      Key: principalImageName
    };
    const secondary = {
      Bucket: environment.bucket,
      Key: secondaryImageName
    }
    this.deleteObjectS3(principal).then((resp: any) => {
      this.deleteObjectS3(secondary).then((res: any) => {
        this.deleteNewsFireStore(element.id)
      }, (error) => {
        this.deleteNewsFireStore(element.id)
        console.log(`No se pudo eliminar el archivo ${secondaryImageName} del s3.`)
      })
    }, error => {
      console.log(`No se pudo eliminar el archivo ${principalImageName} del s3.`)
      this.deleteObjectS3(secondary).then((res: any) => {
        this.deleteNewsFireStore(element.id)
      }, (error) => {
        this.deleteNewsFireStore(element.id)
        console.log(`No se pudo eliminar el archivo ${secondaryImageName} del s3.`)
      })
    }); 
    
    // this.awsService.deleteFile(principalImageName).subscribe(
    //   res => {
    //     this.awsService.deleteFile(secondaryImageName).subscribe(res2 => {
    //       this.firestoreService.deleteNews(element.id);
    //       this.spinner.hide();
    //       this.confirmDelete.show();
    //     });
    //   },
    //   err => {
    //     this.awsService.deleteFile(secondaryImageName).subscribe(
    //       res2 => {
    //         this.firestoreService.deleteNews(element.id);
    //         this.spinner.hide();
    //         this.confirmDelete.show();
    //       },
    //       err => {
    //         this.firestoreService.deleteNews(element.id);
    //         this.spinner.hide();
    //         this.confirmDelete.show();
    //       }
    //     );
    //   }
    // );
  }

  deleteNewsFireStore(id){
    this.firestoreService.deleteNews(id);
    this.spinner.hide();
    this.confirmDelete.show();
  }

  deleteObjectS3(data){
    return new Promise((resolve, reject) => {
      this.s3.deleteObject(data, function(err, data) {
        if(err){
          console.log(err);
          reject(err);
        }
        resolve(data);
      });
    });    
  }

  trasformDate(tmstmp) {
    const seconds = tmstmp['seconds'];
    const date = new Date((seconds * 1000));
    return date;
  }

  comprareByCreationDate(a: Noticia, b: Noticia) {
    if (a.creationDate < b.creationDate) {
      return 1;
    } else if (a.creationDate > b.creationDate) {
      return -1;
    } else {
      return 0;
    }
  }

}

@Component({
  selector: "preview-dialog",
  templateUrl: "preview-dialog.html"
})
export class PreviewDialog {
  constructor(
    public dialogRef: MatDialogRef<PreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

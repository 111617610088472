import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/user';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isSubmitted = false;
  badCredentials = false;

  constructor(
    private userService: UsersService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.loginForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  get formControls() {
    return this.loginForm.controls;
  }

  get user() {
    return this.loginForm.get('user');
  }

  get password() {
    return this.loginForm.get('password');
  }

  /**
   * Comprueba las credenciales compartidas
   */
  login() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    const user = new User();
    user.username = this.loginForm.value.user;
    user.password = this.loginForm.value.password;
    this.userService.login(this.loginForm.value.user, this.loginForm.value.password).subscribe(res => {
      console.log("Respuesta del login: " + res);
    }, err => {
      console.log("Error en el login: " + err);
    });

    // if (this.loginForm.value.user === this.loginForm.value.password) {
    //   this.router.navigateByUrl('/noticias');
    // } else {
    //   this.badCredentials = true;
    //   return;
    // }
  }
}

import { OwlDateTimeIntl } from 'ng-pick-datetime';

export class DateTimeIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel = 'Sumar un segundo';

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel = 'Restar un segundo';

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel = 'Sumar un minuto';

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel = 'Restar un minuto';

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel = 'Sumar una hora';

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel = 'Restar una hora';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Mes anterior';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Mes siguiente';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Año anterior';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Año siguiente';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = '21 años previos';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = '21 años siguientes';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Cambiar a vista mensual';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = 'Cambiar a vista anual';

  /** A label for the cancel button */
  cancelBtnLabel = 'Cancelar';

  /** A label for the set button */
  setBtnLabel = 'Aceptar';

  /** A label for the range 'from' in picker info */
  rangeFromLabel = 'de';

  /** A label for the range 'to' in picker info */
  rangeToLabel = 'a';

  /** A label for the hour12 button (AM) */
  hour12AMLabel = 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel = 'PM';
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-principal-layout",
  templateUrl: "./principal-layout.component.html",
  styleUrls: ["./principal-layout.component.scss"]
})
export class PrincipalLayoutComponent implements OnInit {
  showMenu = false;
  toggled = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParamMap.get("embd") === "1") {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }

    // Leemos el estado de visualización del menú
    if (!localStorage.getItem("toggled")) {
      localStorage.setItem("toggled", "false");
      this.toggled = false;
    } else {
      if (localStorage.getItem("toggled") === "true") {
        localStorage.setItem("toggled", "false");
        this.toggled = false;
      } else {
        localStorage.setItem("toggled", "true");
        this.toggled = false;
      }
    }
  }

  regToggle() {
    if (localStorage.getItem("toggled") === "true") {
      localStorage.setItem("toggled", "false");
      this.toggled = false;
    } else {
      localStorage.setItem("toggled", "true");
      this.toggled = false;
    }
  }

  public salir() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NoticiaImpl } from "src/app/classes/noticia-impl";
import { Noticia } from "src/app/interfaces/noticia";
import { environment } from "../../../environments/environment";
import { FirestoreService } from "../../services/firestore.service";
import { Categoria } from "./../../interfaces/categoria";
import { SwalComponent } from "@toverux/ngx-sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
import { DateTimeAdapter } from "ng-pick-datetime";
import { AuthService } from "src/app/services/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import * as AWS from 'aws-sdk';

@Component({
  selector: "app-noticias-alta",
  templateUrl: "./noticias-alta.component.html",
  styleUrls: ["./noticias-alta.component.scss"]
})
export class NoticiasAltaComponent implements OnInit {
  newsForm: FormGroup;
  @ViewChild("catImage") catImage: ElementRef;
  @ViewChild("mainImage") mainImage: ElementRef;
  @ViewChild("secondImage") secondImage: ElementRef;
  @ViewChild("confirmNews") private confirmNews: SwalComponent;
  @ViewChild("errorSaveNews") private errorSaveNews: SwalComponent;

  catImageUri: any;
  mainImageUri: any;
  secondImageUri: any;

  catImageUrl: string;
  mainImageUrl: string;
  secondImageUrl: string;

  mainImageName: string;
  secondImageName: string;

  titleColor = "#000000";
  smlDescColor = "#000000";
  keyColor = "#000000";
  floatLabel = "always";
  kwColorPos = "left";
  htmlContent: string;
  newsContent: string;

  mainImageReq = false;
  secondImageReq = false;
  htmlControlReq = false;

  catCompanys: any;
  catCategorys: any;
  catKeywords: any;
  newsObject: Noticia = new NoticiaImpl();
  newsId: string;
  showMenu = false;

  create = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',   
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ] 
  };//uploadUrl: 'https://7u3rw462v6.execute-api.us-east-1.amazonaws.com/dev/upload-file'//environment.apiURL

  fonts: string[] = [
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Times",
    "Courier New",
    "Courier",
    "Verdana",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman"
  ];

  prioritys: number[] = [1, 2, 3, 4, 5];
  s3: any;  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    public firestoreService: FirestoreService,
    public auth: AuthService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    public sanitizer: DomSanitizer
  ) {
    // dateTimeAdapter.setLocale("fr");
    AWS.config.update(environment.awsConfig);
    this.s3 = new AWS.S3();
  }

  ngOnInit() {
    this.spinner.show();
    this.newsForm = new FormGroup({
      titleControl: new FormControl("", [Validators.required, Validators.maxLength(50)]),
      titleFontControl: new FormControl("", [Validators.required]),
      titleColorControl: new FormControl("", [Validators.required]),
      // titleSizeControl: new FormControl("", [Validators.required]),

      smlDescControl: new FormControl("", [Validators.required, Validators.maxLength(70)]),
      smlDescFontControl: new FormControl("", [Validators.required]),
      smlDescColorControl: new FormControl("", [Validators.required]),
      companyControl: new FormControl("", [Validators.required]),

      catControl: new FormControl("", [Validators.required]),
      // keyControl: new FormControl("", [Validators.required]),

      datePubControl: new FormControl({ value: "" }, [Validators.required]),
      dateInacControl: new FormControl({ value: "" }, [Validators.required]),
      mainImageControl: new FormControl("", [Validators.required]),
      secondImageControl: new FormControl("", [Validators.required]),

      htmlControl: new FormControl("", [Validators.required]),
      priorityControl: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.max(5),
          Validators.min(1)
        ])
      )
    });

    this.getCompanys();
    this.getCategorys();
    this.getKeywords();

    if (this.route.snapshot.queryParamMap.get("embd") === "1") {
      this.showMenu = false;
    } else {
      this.showMenu = true;
    }

    this.route.params.subscribe(params => {
      if (params.id) {
        this.create = false;
        this.initiateForm(params.id);
      } else {
        this.create = true;
        this.spinner.hide();
      }
    });
  }

  /**
   * Inicializa el form de la noticia
   * @param id
   */
  initiateForm(id: string) {
    this.firestoreService.getNewsById(id).subscribe(elem => {
      this.newsId = elem.payload.id;
      
      if (this.newsId) {
        this.newsObject = elem.payload.data();
        console.log(this.newsObject)
        this.smlDescColor = elem.payload.data().partialDescription.color;
        this.titleColor = elem.payload.data().title.color;

        this.newsForm.patchValue({
          titleControl: this.newsObject.title.text,
          titleFontControl: this.newsObject.title.font,
          titleColorControl: this.newsObject.title.color,
          // titleSizeControl: this.newsObject.title.size,

          smlDescControl: this.newsObject.partialDescription.text,
          smlDescFontControl: this.newsObject.partialDescription.font,
          smlDescColorControl: this.newsObject.partialDescription.color,

          companyControl: this.generateCompanyIdsArray(
            this.newsObject.companys
          ),
          catControl: this.newsObject.category.text,
          // keyControl: this.generateKeywordsArray(this.newsObject.keywords),
          datePubControl: new Date(
            this.newsObject.displayDate["seconds"] * 1000
          ),
          dateInacControl: new Date(this.newsObject.hideDate["seconds"] * 1000),
          mainImageControl: this.newsObject.principalImage,
          secondImageControl: this.newsObject.secondaryImage,
          htmlControl: this.newsObject.body,
          priorityControl: this.newsObject.priority
        });
        this.titleColor = this.newsObject.title.color;
        this.smlDescColor = this.newsObject.partialDescription.color;
        this.mainImageUri = this.newsObject.principalImage;
        this.secondImageUri = this.newsObject.secondaryImage;
        this.spinner.hide();
      } else {
        this.newsForm.patchValue({
          titleColorControl: "#000000",
          smlDescColorControl: "#000000",
          priorityControl: 3
        });
        this.smlDescColor = "#000000";
        this.titleColor = "#000000";
        this.spinner.hide();
      }
    });
  }

  backNews() {
    const embeded = "/noticias" + (!this.showMenu ? "?embd=1" : "");
    this.router.navigateByUrl(embeded);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.newsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  saveNew() {
    let pURL;
    if (this.mainImageUrl == null) {
      pURL = this.newsForm.value["mainImageControl"];
      // this.mainImageReq = true;
    } else {
      pURL = this.mainImageUrl; //`${environment.s3URL}` + this.mainImageUrl;
      // this.mainImageReq = false;
    }
    let sURL;
    if (this.secondImageUrl == null) {
      sURL = this.newsForm.value["secondImageControl"];
      // this.secondImageReq = true;
    } else {
      sURL = this.secondImageUrl; //`${environment.s3URL}` + this.secondImageUrl;
      // this.secondImageReq = false;
    }

    this.newsForm.patchValue({
      titleColorControl: this.titleColor,
      smlDescColorControl: this.smlDescColor,
      mainImageControl: pURL,
      secondImageControl: sURL
    });

    this.mainImageUrl || this.newsId
      ? (this.mainImageReq = false)
      : (this.mainImageReq = true);
    this.secondImageUrl || this.newsId
      ? (this.secondImageReq = false)
      : (this.secondImageReq = true);
    this.newsForm.value["htmlControl"]
      ? (this.htmlControlReq = false)
      : (this.htmlControlReq = true);

    console.log(this.findInvalidControls());
    if (this.newsForm.valid) {
      this.spinner.show();
      
      console.log(this.newsForm.invalid);
      console.log(this.mainImageReq);
      console.log(this.secondImageReq);
      console.log(this.htmlControlReq);

      if (
        !this.newsForm.invalid &&
        !this.mainImageReq &&
        !this.secondImageReq &&
        !this.htmlControlReq
      ) {
        const object = this.generateNewObject(this.newsForm);

        if (this.newsId) {
          this.firestoreService
            .updateNews(object, this.newsId)
            .then(() => {
              this.spinner.hide();
              this.confirmNews.show();
            })
            .catch(error => {
              this.spinner.hide();
              this.errorSaveNews.show();
            });
        } else {
          this.firestoreService
            .createNew(object)
            .then(() => {
              this.spinner.hide();
              this.confirmNews.show();
            })
            .catch(error => {
              this.spinner.hide();
              this.errorSaveNews.show();
            });
        }
      }else{
        console.log('No entra al ifffff')
      }
    }
  }

  previewFile(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    let file: any;

    switch (target.attributes.id.nodeValue) {
      case "mainImage":
        file = this.mainImage.nativeElement.files[0];
        this.mainImageName = file.name;
        break;
      case "secondImage":
        file = this.secondImage.nativeElement.files[0];
        this.secondImageName = file.name;
        break;
    }
    // if (file && this.validateFile(file)) {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      switch (target.attributes.id.nodeValue) {
        case "mainImage":
          this.mainImageUri = reader.result;
          this.saveFile(
            this.mainImageUri,
            target.attributes.id.nodeValue,
            this.mainImageName
          );
          break;
        case "secondImage":
          this.secondImageUri = reader.result;
          this.saveFile(
            this.secondImageUri,
            target.attributes.id.nodeValue,
            this.secondImageName
          );
          break;
      }
    };

    // } else {
    // this.errorMsg = 'File must be jpg, png, or gif and cannot be exceed 500 KB in size'
    // }
  }

  saveFile(imageUri, url, fileName) {
    
    if (imageUri.length > 0) {
      const base64File = imageUri.split(",")[1];

      // const token = JSON.parse(this.auth.getToken());

      // const data = { image: base64File, name: fileName};
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     "Content-Type": "application/json"
      //     // 'X-Amz-Security-Token': token.jwtToken,
      //   })
      // };
      /****************/      
      let type = imageUri.split(';')[0];
      var blob = this.b64ToBlob(base64File, {type: type.split(':')[1]})
      const d = {image: blob, name: fileName.split('.')[0], type}
      this.uploadS3(d).then((res:any) => {
        switch(url) {
          case "mainImage":
            this.mainImageUrl = res.urlFile;
            this.newsForm.patchValue({
              mainImageControl: res.urlFile
            });
            this.mainImageReq = false;
            break;
          case "secondImage":
            this.secondImageUrl = res.urlFile;
            this.newsForm.patchValue({
              secondImageControl: res.urlFile
            });
            this.secondImageReq = false;
            break;
        }
      },err => {
          console.log(err)
      });
      /*************/

      // this.http
      //   .post(`${environment.apiURL}/upload-file`, data, httpOptions)
      //   .subscribe(
      //     res => {
      //       switch (url) {
      //         case "mainImage":
      //           this.mainImageUrl = res["name"];
      //           this.newsForm.patchValue({
      //             mainImageControl: `${environment.s3URL}` + this.mainImageUrl
      //           });
      //           this.mainImageReq = false;
      //           break;
      //         case "secondImage":
      //           this.secondImageUrl = res["name"];
      //           this.newsForm.patchValue({
      //             secondImageControl:
      //               `${environment.s3URL}` + this.secondImageUrl
      //           });
      //           this.secondImageReq = false;
      //           break;
      //       }
      //     },
      //     err => {
      //       console.log("Could not upload image." + JSON.stringify(err));

      //       // this.errorMsg = 'Could not upload image.';
      //     }
      //   );
    }
  }

  b64ToBlob(b64, t) {
    console.log(t)
    var binary = atob(b64);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], t);
  }

  uploadS3(data: any){
    const imageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png'
    ];
    return new Promise((resolve, reject) => {
      if (data.image.size < 500000 && imageTypes.includes(data.type.split(':')[1])) {
        const fileName = data.name.replace(/ /g, "_") + `${Math.floor(Date.now() / 1000)}.${data.type.split('/')[1]}`;
        const d = new Date();
        const path = 'Conekta/' + d.getFullYear() + '/' + (d.getMonth() + 1 < 10 ? '0' + d.getMonth() + 1 : d.getMonth() + 1) + '/' + (d.getDate() < 10 ? '0' + d.getDate(): d.getDate());
        const bucket = environment.bucket;
        const params = {
          Body: data.image,
          Key: path + '/' + fileName,
          Bucket: bucket,
          ContentType: data.type.split(':')[1],
          ACL: 'public-read',
          Metadata: { 
            'zeus-identifier': 'uploadedByZEUS' 
          }
        };
        console.log(params)
        this.s3.putObject(params, (err, data) => {
            if (err) {
                console.log('Error: ', err);
                reject(err);
            }
            console.log('Success s3 => ', data)
            resolve({
                data: data,
                name: fileName,
                urlFile: 'https://s3.amazonaws.com/' + bucket + '/' + path + '/' + fileName
            });
        });
      }else{
        reject(new Error('Archivo no valido'))
      }
    });    
  }

  /**
   * Obtiene el catálogo de empresas
   */
  getCompanys() {
    let companys = this.firestoreService.getCompanys();

    companys.subscribe(companysSnapshot => {
      this.catCompanys = [];
      companysSnapshot.forEach(element => {
        this.catCompanys.push({
          id: element.payload.doc.id,
          data: element.payload.doc.data()
        });
      });
    });
  }

  /**
   * Obtiene el catálogo de empresas
   */
  getCategorys() {
    let categorys = this.firestoreService.getCategorys();

    categorys.subscribe(categorysSnapshot => {
      this.catCategorys = [];
      categorysSnapshot.forEach(element => {
        this.catCategorys.push({
          id: element.payload.doc.id,
          data: element.payload.doc.data()
        });
      });
    });
  }

  /**
   * Obtiene el catálogo de empresas
   */
  getKeywords() {
    let keywords = this.firestoreService.getKeywords();

    keywords.subscribe(keywordsSnapshot => {
      this.catKeywords = [];
      keywordsSnapshot.forEach(element => {
        this.catKeywords.push({
          id: element.payload.doc.id,
          data: element.payload.doc.data()
        });
      });
    });
  }

  /**
   * Genera el arreglo con los datos de las keywords seleccionadas
   */
  generateKeywordsObject(selected: []) {
    const keywords = [];
    selected.forEach(keyword => {
      this.catKeywords.forEach(element => {
        if (keyword === element.data.name) {
          keywords.push({
            name: element.data.name,
            color: element.data.color
          });
        }
      });
    });
    return keywords;
  }

  /**
   * Genera el arreglo con los datos de las keywords seleccionadas
   */
  generateCategoryObject(selected) {
    let category: Categoria;
    this.catCategorys.forEach(element => {
      if (selected === element.data.name) {
        category = {
          text: element.data.name,
          image: element.data.imageURL
        };
      }
    });
    return category;
  }

  generateCompanysObject(companys) {
    const companysObject: any = {};
    companys.forEach(element => {
      this.catCompanys.forEach(comp => {
        if (comp.id === element) {
          companysObject[element] = {
            name: comp.data.name,
            exist: true
          };
        }
      });
    });
    return companysObject;
  }

  generateCompanyIdsArray(companys) {
    let companysIds = [];
    for (let company in companys) {
      companysIds.push(company);
    }
    return companysIds;
  }

  generateKeywordsArray(keywords) {
    let keywordsArray = [];
    keywords.forEach(element => {
      keywordsArray.push(element.name);
    });
    return keywordsArray;
  }

  generateNewObject(form) {
    // const keywordsArray = this.generateKeywordsObject(form.value["keyControl"]);
    const categoryObject = this.generateCategoryObject(
      form.value["catControl"]
    );
    const companysObject = this.generateCompanysObject(
      form.value["companyControl"]
    );

    const newsObject = {
      title: {
        text: form.value["titleControl"],
        font: form.value["titleFontControl"],
        size: 18,
        color: form.value["titleColorControl"]
      },
      partialDescription: {
        text: form.value["smlDescControl"],
        font: form.value["smlDescFontControl"],
        color: form.value["smlDescColorControl"]
      },
      companys: companysObject,
      principalImage: form.value["mainImageControl"],
      secondaryImage: form.value["secondImageControl"],
      category: categoryObject,
      body: form.value["htmlControl"],
      creationDate: new Date(),
      displayDate: new Date(form.value["datePubControl"]),
      hideDate: new Date(form.value["dateInacControl"]),
      // keywords: keywordsArray,
      priority: +(form.value["priorityControl"])
    };

    return newsObject;
  }

  colorChange(event) {
    switch (event.target.id) {
      case "titleColor":
        this.titleColor = event.target.value;
        break;
      case "smlDescColor":
        this.smlDescColor = event.target.value;
        break;
    }
  }

  get formControls() {
    return this.newsForm.controls;
  }

  get titleControl() {
    return this.newsForm.get("titleControl");
  }

  get titleFontControl() {
    return this.newsForm.get("titleFontControl");
  }

  get titleColorControl() {
    return this.newsForm.get("titleColorControl");
  }

  get titleSizeControl() {
    return this.newsForm.get("titleSizeControl");
  }

  get smlDescControl() {
    return this.newsForm.get("smlDescControl");
  }

  get smlDescFontControl() {
    return this.newsForm.get("smlDescFontControl");
  }

  get smlDescColorControl() {
    return this.newsForm.get("smlDescColorControl");
  }

  get companyControl() {
    return this.newsForm.get("companyControl");
  }

  get catControl() {
    return this.newsForm.get("catControl");
  }

  get keyControl() {
    return this.newsForm.get("keyControl");
  }

  get datePubControl() {
    return this.newsForm.get("datePubControl");
  }

  get dateInacControl() {
    return this.newsForm.get("dateInacControl");
  }

  get priorityControl() {
    return this.newsForm.get("priorityControl");
  }
}

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from 'angularfire2';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoriasModule } from './components/categorias/categorias.module';
import { EstadisticasModule } from './components/estadisticas/estadisticas.module';
import { KeywordsModule } from './components/keywords/keywords.module';
import { LoginModule } from './components/login/login.module';
import { NoticiasAltaModule } from './components/noticias-alta/noticias-alta.module';
import { NoticiasModule } from './components/noticias/noticias.module';
import { UsuariosModule } from './components/usuarios/usuarios.module';
import { LayoutModule } from './layout/layout.module';



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    LoginModule,
    NoticiasModule,
    NoticiasAltaModule,
    KeywordsModule,
    CategoriasModule,
    EstadisticasModule,
    UsuariosModule,
    HttpClientModule,
    AngularFireModule,
    NgxSpinnerModule
  ],
  // providers: [
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: TokenInterceptorService,
  //     multi: true
  //   }
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {}

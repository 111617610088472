import { DateTimeIntl } from "./../../classes/datetime-intl";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule, MatNativeDateModule } from "@angular/material";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { AngularFireModule } from "angularfire2";
import { AngularFirestore } from "angularfire2/firestore";
import {
  OwlDateTimeModule,
  OWL_DATE_TIME_LOCALE,
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeIntl
} from "ng-pick-datetime";
import {
  OwlMomentDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS
} from "ng-pick-datetime-moment";
import { ColorBlockModule } from "ngx-color/block";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../../../environments/environment";
import { NoticiasRoutingModule } from "./noticias-alta-routing.module";
import { NoticiasAltaComponent } from "./noticias-alta.component";

export const MY_MOMENT_FORMATS = {
  parseInput: "L LT",
  fullPickerInput: "L LT",
  datePickerInput: "L",
  timePickerInput: "LT",
  monthYearLabel: "MMM YYYY",
  dateA11yLabel: "LL",
  monthYearA11yLabel: "MMMM YYYY"
};

@NgModule({
  declarations: [NoticiasAltaComponent],
  imports: [
    CommonModule,
    NoticiasRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    AngularEditorModule,
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    ColorBlockModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    AngularFirestore,
    OwlMomentDateTimeModule,
    { provide: OWL_DATE_TIME_LOCALE, useValue: "fr" },
    { provide: OwlDateTimeIntl, useClass: DateTimeIntl },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: false }
    }
  ]
})
export class NoticiasAltaModule {}

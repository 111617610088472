import { NoticiaImpl } from 'src/app/classes/noticia-impl';
import { Noticia } from 'src/app/interfaces/noticia';
import { Injectable } from "@angular/core";
import { AngularFirestore } from "angularfire2/firestore";

@Injectable({
  providedIn: "root"
})
export class FirestoreService {
  constructor(private angularFirestore: AngularFirestore) {}

  /**
   * Obtiene el catálogo de empresas
   */
  public getCompanys() {
    const catCompanys = this.angularFirestore
      .collection("Catalogs")
      .doc("Companys")
      .collection("Data")
      .snapshotChanges();

    return catCompanys;
  }

  /**
   * Obtiene el catálogo de Categorías
   */
  public getCategorys() {
    const catCategorys = this.angularFirestore
      .collection("Catalogs")
      .doc("Categorys")
      .collection("Data")
      .snapshotChanges();

    return catCategorys;
  }

  /**
   * Obtiene el catálogo de Keywords
   */
  public getKeywords() {
    const catKeywords = this.angularFirestore
      .collection("Catalogs")
      .doc("Keywords")
      .collection("Data")
      .snapshotChanges();

    return catKeywords;
  }

  /**
   * Guarda la noticia
   *
   * @param form
   */
  public createNew(newObject) {
    return this.angularFirestore.collection("News").add(newObject);
  }

  public updateNews(newsObject, id) {
    return this.angularFirestore
      .collection("News")
      .doc(id)
      .set(newsObject);
  }

  /**
   * Obtiene todas las noticias
   */
  getNews() {
    return this.angularFirestore.collection<Noticia>("News", ref => ref.orderBy('creationDate', 'desc')).snapshotChanges();
  }

  /**
   * Elimina la noticia
   * @param id
   */
  deleteNews(id) {
    this.angularFirestore.doc("News/" + id).delete();
  }

  /**
   * Obtiene la noticia
   * @param id
   */
  getNewsById(id: string) {
    return this.angularFirestore.doc<Noticia>("News/" + id).snapshotChanges();
  }

  getGreaterNews() {
    const greaterNews = this.angularFirestore.collection<Noticia>("News", ref =>
      ref.where("hideDate", ">=", new Date()).orderBy('hideDate', 'desc')
    );

    return greaterNews.snapshotChanges();
  }
}
